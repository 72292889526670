.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is on top */
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px; /* Adjust width as needed */
  max-height: 80vh; /* Max height for the modal */
  overflow-y: auto; /* Allow vertical scrolling */
  position: relative; /* Positioning for absolute children */
}

h2 {
  margin-top: 0; /* Remove top margin from headings */
}

button {
  margin-top: 20px; /* Space the close button from content */
}

.terms-info {
  color: black;
}

.privacy-info {
  color: black;
}

.cookies-info {
  color: black;
}

@media only screen and (max-width: 768px) {
  .modal-content {
    max-width: 400px; /* Reduce width */
    padding: 15px; /* Reduce padding */
  }

  h2 {
    font-size: 24px; /* Adjust heading size */
  }

  button {
    font-size: 16px; /* Adjust button font size */
    margin-top: 15px; /* Adjust spacing */
  }

  .terms-info,
  .privacy-info,
  .cookies-info {
    font-size: 16px; /* Adjust content font size */
  }
}
@media only screen and (max-width: 480px) {
  .modal-content {
    max-width: 300px; /* Further reduce width */
    padding: 10px; /* Further reduce padding */
  }

  h2 {
    font-size: 20px; /* Smaller heading size */
  }

  button {
    font-size: 14px; /* Smaller button font size */
    margin-top: 10px; /* Adjust margin */
  }

  .terms-info,
  .privacy-info,
  .cookies-info {
    font-size: 14px; /* Further reduce content font size */
  }
}
