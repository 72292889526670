.footer {
  margin: 50px 170px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 5px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
}

.footer-top-left p {
  font-size: 14px;
  max-width: 400px;
  font-family: Montserrat;
}

.footer-top-right {
  font-size: 16px;
  max-width: 800px;
  font-family: Montserrat;
  padding-top: 70px;
}

.footer-top-right p span {
  font-weight: bold;
  display: block;
  padding-bottom: 10px;
}

.footer-email-input {
  display: flex;
  gap: 30px;
  padding: 20px 30px;
  border-radius: 50px;
  background: #32323b;
  padding-right: 80px;
}

.footer-email-input input {
  outline: none;
  border: none;
  background: transparent;
  color: #a0a0a0;
  font-family: Montserrat;
  font-size: 18px;
}

.footer-subscribe {
  font-size: 20px;
  padding: 22px 50px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #b515ffcf 106.28%);
  cursor: pointer;
  transition: 0.3s;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: Montserrat;
}

.footer-bottom-right {
  display: flex;
  gap: 50px;
  margin-bottom: 50px;
}

.footer-email-input:hover {
  border: 2px solid white;
}

.footer-subscribe:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.footer-top-left img {
  width: 100px;
  height: 100px;
  background: transparent;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  bottom: -50px;
}

.back-to-top-btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 18px 50px;
  border-radius: 40px;
  border: 2px solid #fff;
  background: linear-gradient(264deg, #df8908 -5.09%, #b515ffdd 90.28%);
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease;
  z-index: 1000;
}

.back-to-top-btn:hover {
  transform: scale(1.1);
  transition: 0.3s ease;
}

.footer-bottom hr {
  margin-left: 20px; /* Adjust the value as needed */
  margin-right: 20px; /* Adjust the value as needed */
}

.footer-top-right p {
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .footer {
    margin: 50px 70px;
  }
  .footer-top {
    flex-direction: column;
    gap: 0px;
  }
  .footer-top-right {
    padding-top: none;
  }
  .footer-top-left img {
    display: none;
  }
  .footer-bottom {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
  .back-to-top-btn {
    padding: 14px 30px;
    font-size: 10px;
  }
  .footer hr {
    margin-left: 0;
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    margin: 30px 20px;
    padding-left: 20px; /* Narrow margins for smaller screens */
  }

  .footer-top {
    flex-direction: column;
    gap: 0px;
  }

  .footer-top-right {
    padding-top: 0; /* Remove padding */
  }

  .footer-top-left img {
    display: none;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px; /* Reduce margin for smaller screens */
  }

  .back-to-top-btn {
    padding: 10px 20px; /* Adjust padding */
    font-size: 8px; /* Reduce font size for smaller screens */
  }

  .footer hr {
    margin-left: 0;
  }
}
