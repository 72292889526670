@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background: #161513;
  color: white;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
  width: 100%;
}

@media (max-width: 768px) {
  body {
    padding: 0;
  }
}

.gradient-text {
  background: linear-gradient(90deg, #f59607 0%, #b515ff 50%, #b515ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  display: inline-block;
  padding: 0.1em 0;
}
